<template>
  <div style="max-height: 60vh; overflow-y: auto; padding-inline: 1rem">
    <!-- ! TODO: EMIT Response to Voice Form Data -->
    <el-form
      @submit.prevent
      label-position="top"
      :model="formData"
      :rules="rules"
      ref="ruleForm"
      id="translation-form"
    >
      <div class="form-item">
        <el-form-item
          :label="$t('ai.translation.original_message')"
          prop="originalMessage"
        >
          <el-input
            type="textarea"
            :placeholder="$t('ai.translation.original_message')"
            maxlength="459"
            v-model="formData.originalMessage"
            show-word-limit
            resize="none"
            :autosize="{ minRows: 3, maxRows: 6 }"
            @input="handleChange"
          >
          </el-input>
        </el-form-item>
        <div class="form-item target-languages">
          <el-form-item
            :label="$t('ai.translation.confirm_original_language')"
            prop="originalLanguage"
            v-if="changeOriginalLanguage"
          >
            <el-select
              :placeholder="$t('ai.translation.confirm_original_language')"
              v-model="formData.originalLanguage"
            >
              <el-option
                v-for="item in availableLanguages"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('ai.translation.confirm_original')"
            prop="targetLanguage"
          >
            <el-select
              :placeholder="$t('ai.translation.select_language')"
              v-model="formData.targetLanguage"
            >
              <el-option
                v-for="item in availableLanguages"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="form-item">
        <el-form-item
          :label="$t('ai.translation.generated_audio')"
          style="width: 100%"
        >
          <audio
            controls
            style="width: 100%"
            :style="{
              opacity:
                responseData.data?.voice_url?.length > 0 ||
                responseData.voice_url?.length > 0
                  ? 1
                  : 0.3,
            }"
            :key="responseData.data?.voice_url || responseData.voice_url"
          >
            <source
              :src="responseData.data?.voice_url || responseData.voice_url"
              type="audio/mp3"
            />
            <source
              :src="responseData.data?.voice_url || responseData.voice_url"
              type="audio/wav"
            />
          </audio>
        </el-form-item>
      </div>
    </el-form>
    <footer style="position: sticky; bottom: 0">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <el-button type="text" @click="$emit('close')" :disabled="isLoading">
          <span>{{ $t("misc.button.close") }}</span>
        </el-button>
        <el-button
          type="primary"
          @click="generateTextToSpeech"
          :disabled="isLoading"
          :loading="isLoading"
          form="translation-form"
        >
          {{ $t("ai.translation.generate_audio") }}
        </el-button>
        <el-button
          type="danger"
          @click="$emit('close', 'send-voice')"
          :disabled="
            !!responseData.data?.voice_url ||
            !responseData.voice_url ||
            isLoading
          "
        >
          <span>{{ $t("misc.button.send") }}</span>
        </el-button>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "aiTextToSpeech",
  inject: ["campaign", "isCreateTemplate"],
  data() {
    return {
      availableLanguages: [
        { label: "English", value: "en" },
        { label: "Twi", value: "tw" },
        { label: "Ga", value: "gaa" },
        { label: "Ewe", value: "ee" },
        { label: "Fante", value: "fat" },
        { label: "Dagbani", value: "dag" },
        { label: "Gurene", value: "gur" },
        { label: "Yoruba", value: "yo" },
        { label: "Kikuyu", value: "ki" },
        { label: "Luo", value: "luo" },
        { label: "Kimeru", value: "mer" },
      ],
      formData: {
        originalMessage: "",
        originalLanguage: "en",
        targetLanguage: "",
      },
      isLoading: false,
      changeOriginalLanguage: false,
      disabledSendButton: true,
      rules: {
        originalMessage: [
          {
            required: true,
            message: this.$t("ai.translation.form.original_message.required"),
            trigger: "blur",
          },
          {
            min: 1,
            message: this.$t("ai.translation.form.original_message.required"),
            trigger: "blur",
          },
          {
            max: 459,
            message: this.$t("ai.translation.form.original_message.max"),
            trigger: "blur",
          },
        ],
        originalLanguage: [
          {
            required: true,
            message: this.$t("ai.translation.form.original_language.required"),
            trigger: "change",
          },
        ],
        targetLanguage: [
          {
            required: true,
            message: this.$t("ai.translation.form.target_language.required"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    availableTargetLanguages() {
      return this.availableLanguages.filter(
        (lang) => lang.value !== this.formData.originalLanguage
      );
    },
    /**@import {VoiceOutput} from '@/state/modules/ai' */

    /**@returns {{
       * data:VoiceOutput
      }} */
    responseData() {
      return this.$store.getters["ai/getVoiceOutput"];
    },
    selectedLanguageLabel() {
      return this.availableLanguages.find(
        (lang) => lang.value === this.formData.originalLanguage
      ).label;
    },
    getTextOutput() {
      return this.$store.getters["ai/getTextOutput"];
    },
  },
  created() {
    this.formData.originalMessage =
      this.campaign().message || this.campaign().body;
  },
  watch: {
    campaign: {
      handler() {
        this.formData.originalMessage =
          this.$store.getters["ai/getTextOutput"] ||
          this.campaign().message ||
          this.campaign().body;
      },
      deep: true,
      immediate: true,
    },
    getTextOutput: {
      handler() {
        this.formData.originalMessage = this.getTextOutput;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChange($event) {
      this.formData.originalMessage = $event;
      this.$store.dispatch("ai/setTextOutput", $event);
    },
    async generateTextToSpeech() {
      try {
        this.isLoading = true;
        this.disabledSendButton = true;
        try {
          await this.$refs.ruleForm.validate();
          // shape the data
        } catch (error) {
          return;
        }
        this.$mixpanel.track("AI - Generate Text to Speech", {
          originalLanguage: this.selectedLanguageLabel,
          targetLanguage: this.formData.targetLanguage,
        });
        const payload = {
          is_simulated: false, // ! TOGGLE TO TRUE TO SIMULATE
          language: this.formData.targetLanguage,
          text: this.formData.originalMessage,
          voice_note_title: this.campaign().title,
        };
        await this.$store.dispatch("ai/generateTextToSpeech", payload);
        this.disabledSendButton = false;
        this.$message.success(this.$t("ai.translation.success_audio"));
      } catch (error) {
        this.$message.error(this.$t("ai.translation.error_audio"));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
* >>> .el-form-item__label {
  padding: 0;
  margin: 0;
}

.target-languages {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.target-languages >>> div {
  width: 100%;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
</style>
